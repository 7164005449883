import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  constructor(private apollo: Apollo) {}

  async init(): Promise<void> {
    this.apollo.client.stop();
    await this.apollo.client.resetStore();
    Auth.configure({
      aws_project_region: 'us-west-2',
      aws_cognito_identity_pool_id: 'us-west-2:edff3183-cb5f-4de3-9a15-1326bc9462ed',
      aws_cognito_region: 'us-west-2',
      aws_user_pools_id: 'us-west-2_0hLnTUHZK',
      aws_user_pools_web_client_id: '2a9r1jd31329bomk34s8hfs3b0',
      oauth: {},
    });
  }
}
