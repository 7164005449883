import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from 'src/environments/environment';

import * as fromGeolocation from './geolocation.reducer';
import * as fromMap from './map.reducer';
import * as fromMedia from './media.reducer';
import * as fromRouter from './router.reducer';
import * as fromServiceLine from './service-line.reducer';
import * as fromSite from './site.reducer';
import * as fromTask from './task.reducer';
import * as fromUser from './user.reducer';

export interface State {
  [fromGeolocation.featureKey]: fromGeolocation.State;
  [fromMap.featureKey]: fromMap.State;
  [fromMedia.featureKey]: fromMedia.State;
  [fromRouter.featureKey]: RouterReducerState<any>;
  [fromServiceLine.featureKey]: fromServiceLine.State;
  [fromSite.featureKey]: fromSite.State;
  [fromTask.featureKey]: fromTask.State;
  [fromUser.featureKey]: fromUser.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromGeolocation.featureKey]: fromGeolocation.reducer,
  [fromMap.featureKey]: fromMap.reducer,
  [fromMedia.featureKey]: fromMedia.reducer,
  [fromRouter.featureKey]: routerReducer,
  [fromServiceLine.featureKey]: fromServiceLine.reducer,
  [fromSite.featureKey]: fromSite.reducer,
  [fromTask.featureKey]: fromTask.reducer,
  [fromUser.featureKey]: fromUser.reducer,
};

// Router
// export const selectNavigationId = createSelector(fromRouter.selectRouterState, (state) => state.navigationId);

// Users
export const selectUserState = createFeatureSelector<fromUser.State>(fromUser.featureKey);
export const selectUserId = createSelector(selectUserState, fromUser.getId);
export const selectUserEmail = createSelector(selectUserState, fromUser.getEmail);
export const selectUserPassword = createSelector(selectUserState, fromUser.getPassword);
export const selectUserLoggedIn = createSelector(selectUserState, fromUser.getLoggedIn);
export const selectUserLoading = createSelector(selectUserState, fromUser.getLoading);

// Geolocation
export const selectGeolocationState = createFeatureSelector<fromGeolocation.State>(fromGeolocation.featureKey);
export const selectGeolocationEnabled = createSelector(selectGeolocationState, fromGeolocation.getEnabled);
export const selectGeolocationPermission = createSelector(selectGeolocationState, fromGeolocation.getPermission);
export const selectCurrentPosition = createSelector(selectGeolocationState, fromGeolocation.getLatLng);

// Sites
export const selectSiteState = createFeatureSelector<fromSite.State>(fromSite.featureKey);
export const selectAllSites = createSelector(selectSiteState, fromSite.selectAll);
export const selectSiteEntities = createSelector(selectSiteState, fromSite.selectEntities);

export const selectSitesLoading = createSelector(selectSiteState, fromSite.getLoading);

export const selectCurrentSiteId = createSelector(selectSiteState, fromSite.getCurrentEntityId);
export const selectCurrentSite = createSelector(selectSiteEntities, selectCurrentSiteId, fromSite.getById);

export const selectAllSitesByDistance = createSelector(
  selectAllSites,
  selectCurrentPosition,
  fromSite.getSortedByDistance(fromGeolocation.distance),
);

export const selectAllSitesWithSelected = createSelector(selectAllSitesByDistance, selectCurrentSiteId, (sites, id) =>
  sites.map((site) => ({ ...site, selected: site.id === id })),
);

export const selectNearestSite = createSelector(selectAllSitesByDistance, fromSite.getFirst);

export const selectSiteListIcon = createSelector(selectSiteState, fromSite.getListIcon);
export const selectSiteListMode = createSelector(selectSiteState, fromSite.getIfListMode);

export const selectCurrentSiteName = createSelector(selectCurrentSite, fromSite.getName);
export const selectCurrentSitePosition = createSelector(selectCurrentSite, fromSite.getPosition);

export const selectDisplaySiteDrawer = createSelector(selectCurrentSite, selectSiteListMode, fromSite.getIfDisplayDrawer);

// Tasks
export const selectTaskState = createFeatureSelector<fromTask.State>(fromTask.featureKey);
export const selectAllTasks = createSelector(selectTaskState, fromTask.selectAll);
export const selectAllTasksByCurrentSite = createSelector(selectAllTasks, selectCurrentSiteId, fromTask.getByAllSubjectId);

export const selectTaskEntities = createSelector(selectTaskState, fromTask.selectEntities);

export const selectTaskListIcon = createSelector(selectTaskState, fromTask.getListIcon);
export const selectTaskListMode = createSelector(selectTaskState, fromTask.getIfListMode);

export const selectCurrentTaskId = createSelector(selectTaskState, fromTask.getCurrentEntityId);
export const selectCurrentTask = createSelector(selectTaskEntities, selectCurrentTaskId, fromTask.getById);
export const selectCurrentTaskName = createSelector(selectCurrentTask, fromTask.getName);

export const selectDisplayTaskDrawer = createSelector(selectCurrentTask, selectTaskListMode, fromTask.getIfDisplayDrawer);

export const selectTasksLoading = createSelector(selectTaskState, fromTask.getLoading);

// Service Lines
export const selectServiceLineState = createFeatureSelector<fromServiceLine.State>(fromServiceLine.featureKey);
export const selectAllServiceLines = createSelector(selectServiceLineState, fromServiceLine.selectAllServiceLines);

// Map
export const selectMapState = createFeatureSelector<fromMap.State>(fromMap.featureKey);
export const selectMapEnabled = createSelector(selectMapState, fromMap.getEnabled);
export const selectSitesMapZoom = createSelector(selectMapState, fromMap.getSitesZoom);
export const selectTasksMapZoom = createSelector(selectMapState, fromMap.getTasksZoom);
export const selectCurrentSitesPosition = createSelector(selectMapState, fromMap.getCurrentSitesPosition);
export const selectCurrentTasksPosition = createSelector(selectMapState, fromMap.getCurrentTasksPosition);

// Media
export const selectMediaState = createFeatureSelector<fromMedia.State>(fromMedia.featureKey);
export const selectAllMedia = createSelector(selectMediaState, fromMedia.selectAll);
export const selectMediaForTask = createSelector(selectAllMedia, selectCurrentTaskId, fromMedia.getMediaForTask);
export const selectImagesForTask = createSelector(selectAllMedia, selectCurrentTaskId, fromMedia.getImagesForTask);
export const selectCameraPreviewEnabled = createSelector(selectMediaState, fromMedia.getCameraPreviewEnabled);

// Computed
export const selectAllSiteTaskCounts = createSelector(selectSiteEntities, selectAllTasks, (sites, tasks) =>
  Object.keys(sites).reduce((acc, siteId) => {
    acc[siteId] = tasks.filter((task) => task.subjects?.some((s) => s.id === siteId)).length || 0;
    return acc;
  }, {} as { [key: string]: number }),
);

// Debug
export const testUserAgent = (win: Window, expr: RegExp) => expr.test(win.navigator.userAgent);
const isAndroidFn = (win: Window) => testUserAgent(win, /android|sink/i);
const isAndroid = isAndroidFn(window);

/**
 * console.log all actions
 */
export const debug =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    if (isAndroid) {
      console.log('state', JSON.stringify(state, null, 2));
      console.log('action', JSON.stringify(action, null, 2));
    } else {
      console.log('state', state);
      console.log('action', action);
    }

    return reducer(state, action);
  };

// do not store these states between visits or refreshes
const unstoredReducers = [fromRouter.featureKey, fromMap.featureKey];
// const unstoredReducers = [fromRouter.featureKey, fromMap.featureKey, fromMedia.featureKey];
const keys = Object.keys(reducers).filter((key) => !unstoredReducers.includes(key));
export const localStorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> =>
  localStorageSync({ keys, rehydrate: true })(reducer);

export const metaReducers: MetaReducer<any>[] = environment.production
  ? [localStorageSyncReducer]
  : // : [localStorageSyncReducer];
    [debug, localStorageSyncReducer];
