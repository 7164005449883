import { EntityMap, EntityMapOne, Predicate, Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Site, SiteInput } from 'src/app/models/server/schema';

export const fetchSites = createAction('[Sites] Fetch Sites');
export const fetchSitesSuccess = createAction('[Sites] Fetch Sites Success', props<{ sites: Site[] }>());
export const fetchSitesFailure = createAction('[Sites] Fetch Sites Failure', props<{ error: any }>());

export const fetchSite = createAction('[Sites] Fetch Site', props<{ id: string }>());
export const fetchSiteSuccess = createAction('[Sites] Fetch Site Success', props<{ site: Site }>());
export const fetchSiteFailure = createAction('[Sites] Fetch Site Failure', props<{ error: any }>());

export const createSite = createAction('[Sites] Create Site', props<{ input: SiteInput }>());
export const createSiteSuccess = createAction('[Sites] Create Site Success', props<{ site: Site }>());
export const createSiteFailure = createAction('[Sites] Create Site Failure', props<{ error: any }>());

export const loadSites = createAction('[Sites] Load Sites', props<{ sites: Site[] }>());
export const addSite = createAction('[Sites] Add Site', props<{ site: Site }>());
export const setSite = createAction('[Sites] Set Site', props<{ site: Site }>());
export const upsertSite = createAction('[Sites] Upsert Site', props<{ site: Site }>());
export const addSites = createAction('[Sites] Add Sites', props<{ sites: Site[] }>());
export const upsertSites = createAction('[Sites] Upsert Sites', props<{ sites: Site[] }>());
export const updateSite = createAction('[Sites] Update Site', props<{ update: Update<Site> }>());
export const updateSites = createAction('[Sites] Update Sites', props<{ updates: Update<Site>[] }>());
export const mapSite = createAction('[Sites] Map Site', props<{ entityMap: EntityMapOne<Site> }>());
export const mapSites = createAction('[Sites] Map Sites', props<{ entityMap: EntityMap<Site> }>());
export const deleteSite = createAction('[Sites] Delete Site', props<{ id: string }>());
export const deleteSites = createAction('[Sites] Delete Sites', props<{ ids: string[] }>());
export const deleteSitesByPredicate = createAction('[Sites] Delete Sites By Predicate', props<{ predicate: Predicate<Site> }>());
export const clearSites = createAction('[Sites] Clear Sites');

export const toggleListMode = createAction('[Sites] Toggle List Mode');

export const setCurrentSite = createAction('[Sites] Set Current Site', props<{ id: string }>());
export const clearCurrentSite = createAction('[Sites] Clear Current Site');

export const navigateToSite = createAction('[Sites] Navigate to Site', props<{ id?: string }>());
// export const navigateToSiteList = createAction('[Sites] Navigate to Site List');
