import { ErrorEffects } from './error.effects';
import { GeolocationEffects } from './geolocation.effects';
import { MapEffects } from './map.effects';
import { MediaEffects } from './media.effects';
import { RouterEffects } from './router.effects';
import { ServiceLineEffects } from './service-line.effects';
import { SiteEffects } from './site.effects';
import { TaskEffects } from './task.effects';
import { UserEffects } from './user.effects';

export const rootEffects = [
  ErrorEffects,
  GeolocationEffects,
  MapEffects,
  MediaEffects,
  RouterEffects,
  ServiceLineEffects,
  SiteEffects,
  TaskEffects,
  UserEffects,
];
