import { UrlTree } from '@angular/router';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { createAction, props } from '@ngrx/store';

export const navigateBack = createAction(
  '[Router] Navigate Back',
  props<{ url: string | UrlTree | any[]; options?: NavigationOptions }>(),
);
export const navigateForward = createAction(
  '[Router] Navigate Forward',
  props<{ url: string | UrlTree | any[]; options?: NavigationOptions }>(),
);
export const navigateRoot = createAction(
  '[Router] Navigate Root',
  props<{ url: string | UrlTree | any[]; options?: NavigationOptions }>(),
);
export const pop = createAction('[Router] Navigate Pop');
export const back = createAction('[Router] Navigate Back');
