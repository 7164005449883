import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { concat, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap, toArray } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { PhoenixService } from 'src/app/services/phoenix.service';
import { environment } from 'src/environments/environment';

import { ErrorActions, RouterActions, UserActions } from '../actions';
import { selectUserLoggedIn } from '../reducers';

@Injectable()
export class UserEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      exhaustMap(({ email, password }) =>
        concat(this.auth.login(email, password), this.auth.queryCurrentUser()).pipe(
          toArray(),
          map(([userAttributes, currentUser]) => UserActions.loginSuccess({ user: { ...userAttributes, ...currentUser } })),
          catchError((error) => of(UserActions.loginFailure({ error }))),
        ),
      ),
    ),
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess),
      map(() => RouterActions.navigateRoot({ url: '/sites' })),
    ),
  );

  loginFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginFailure),
      switchMap(({ error }) => {
        const actions = [ErrorActions.handleError({ error })];
        return error?.code === 'UserNotConfirmedException'
          ? [...actions, RouterActions.navigateForward({ url: '/verification' })]
          : actions;
      }),
    ),
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        tap(() => this.store.dispatch(RouterActions.navigateRoot({ url: '/login' }))),
        tap(() => this.apollo.client.stop()),
        tap(async () => await this.apollo.client.resetStore()),
        switchMap(() => this.auth.login(environment.anonymousUser, environment.anonymousPassword)),
      ),
    { dispatch: false },
  );

  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.registerUser),
      exhaustMap(({ firstName, lastName, role, phone, email, password }) =>
        this.auth.registerUser(firstName, lastName, role, phone, email, password).pipe(
          switchMap((userManagement) => {
            if (userManagement.__typename === 'UserRegisterResultSuccess') {
              return [UserActions.registerUserSuccess({ user: { ...userManagement.user }, password })];
            } else if (userManagement.__typename === 'UserAlreadyExistsError') {
              return [
                UserActions.registerUserFailure({ error: userManagement, warning: true }),
                RouterActions.navigateForward({ url: '/login' }),
              ];
            } else if (userManagement.__typename === 'NotAuthorizedException') {
              return [UserActions.registerUserFailure({ error: userManagement })];
            }
            return [];
          }),
          catchError((error) => of(UserActions.registerUserFailure({ error }))),
        ),
      ),
    ),
  );

  registerUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.registerUserSuccess),
      map(() => RouterActions.navigateForward({ url: '/verification' })),
    ),
  );

  registerUserFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.registerUserFailure),
      map(({ error, warning }) => (warning ? ErrorActions.showWarning({ error }) : ErrorActions.handleError({ error }))),
    ),
  );

  confirmRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.confirmRegistration),
      exhaustMap(({ code, email, password }) =>
        this.auth.confirmRegistration(code, email, password).pipe(
          map((userManagement) => {
            if (
              userManagement.__typename === 'CodeMismatchError' ||
              userManagement.__typename === 'NotAuthorizedException' ||
              userManagement.__typename === 'UserNotFoundError'
            ) {
              return UserActions.confirmRegistrationFailure({ error: userManagement });
            } else if (userManagement.__typename === 'UserConfirmationResultSuccess') {
              return UserActions.confirmRegistrationSuccess({ email, password });
            }
          }),
          catchError((error) => of(UserActions.confirmRegistrationFailure({ error }))),
        ),
      ),
    ),
  );

  confirmRegistrationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.confirmRegistrationSuccess),
      map(({ email, password }) => UserActions.login({ email, password })),
    ),
  );

  confirmRegistrationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.confirmRegistrationFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  resendConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resendConfirmation),
      exhaustMap(({ email }) =>
        this.auth.resendConfirmation(email).pipe(
          map(
            (userManagement) =>
              ({
                CodeMismatchError: UserActions.resendConfirmationFailure({ error: userManagement }),
                NotAuthorizedException: UserActions.resendConfirmationFailure({ error: userManagement }),
                UserNotFoundError: UserActions.resendConfirmationFailure({ error: userManagement }),
                UserConfirmationCodeResendResultSuccess: UserActions.resendConfirmationSuccess(),
              }[userManagement.__typename] || UserActions.resendConfirmationFailure({ error: userManagement })),
          ),
          catchError((error) => of(UserActions.resendConfirmationFailure({ error }))),
        ),
      ),
    ),
  );

  // resendConfirmationSuccess$ = createEffect(() => this.actions$.pipe(ofType(UserActions.resendConfirmationSuccess)));

  resendConfirmationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resendConfirmationFailure),
      map(({ error }) => ErrorActions.showWarning({ error })),
    ),
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.forgotPassword),
      exhaustMap(({ email }) =>
        this.auth.forgotPassword(email).pipe(
          map((userManagement) => {
            if (userManagement.__typename === 'UserNotFoundError') {
              return UserActions.forgotPasswordFailure({ error: userManagement });
            } else {
              return UserActions.forgotPasswordSuccess();
            }
          }),
          catchError((error) => of(UserActions.forgotPasswordFailure({ error }))),
        ),
      ),
    ),
  );

  forgotPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.forgotPasswordSuccess),
      map(() => RouterActions.navigateForward({ url: '/verify-password-reset' })),
    ),
  );

  forgotPasswordFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.forgotPasswordFailure),
      map(({ error }) => ErrorActions.showWarning({ error })),
    ),
  );

  verifyPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.verifyPasswordReset),
      exhaustMap(({ code, email, password }) =>
        this.auth.verifyPasswordReset(code, email, password).pipe(
          map((userManagement) =>
            userManagement.__typename === 'CodeMismatchError'
              ? UserActions.verifyPasswordResetFailure({ error: userManagement })
              : UserActions.verifyPasswordResetSuccess(),
          ),
          catchError((error) => of(UserActions.verifyPasswordResetFailure({ error }))),
        ),
      ),
    ),
  );

  verifyPasswordResetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.verifyPasswordResetSuccess),
      map(() => RouterActions.navigateForward({ url: '/login' })),
    ),
  );

  verifyPasswordResetFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.verifyPasswordResetFailure),
      map(() => ErrorActions.showWarning({ error: new Error('incorrect code') })),
    ),
  );

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private auth: AuthService,
    private phoenix: PhoenixService,
    private store: Store,
  ) {
    // this.store
    //   .select(selectUserLoggedIn)
    //   .subscribe(async (loggedIn) => (loggedIn ? await this.phoenix.connect('') : this.phoenix.disconnect()));
  }
}
