import { Injectable } from '@angular/core';
import { CapacitorException, ExceptionCode, PermissionState } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { bindCallback, defer, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  checkPermissions(): Observable<PermissionState> {
    return defer(() => Geolocation.checkPermissions()).pipe(map(({ location }) => location));
  }

  requestPermissions(): Observable<PermissionState> {
    return defer(() => Geolocation.requestPermissions()).pipe(
      map(({ location }) => location),
      catchError(
        (
          err: CapacitorException, // handle unimplemented on web, getPosition() handles the request
        ) => (err.code === ExceptionCode.Unimplemented ? of('granted' as PermissionState) : throwError(err)),
      ),
    );
  }

  watchPosition() {
    return bindCallback(Geolocation.watchPosition)({
      enableHighAccuracy: true,
    }) as unknown as Observable<GeolocationPosition | [null, GeolocationPositionError]>;
  }
}
