import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { MapsService } from 'src/app/services/maps.service';

import { ErrorActions, MapActions } from '../actions';

@Injectable()
export class MapEffects {
  loadMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.loadMap),
      exhaustMap(() =>
        this.maps.load().pipe(
          map(() => MapActions.loadMapSuccess()),
          catchError((error) => of(MapActions.loadMapFailure({ error }))),
        ),
      ),
    ),
  );

  loadMapFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.loadMapFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  constructor(private actions$: Actions, private maps: MapsService) {}
}
