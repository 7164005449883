import { createAction, props } from '@ngrx/store';
import { FileCreateInput, FileCreateMutation, FileUploadRecord } from 'src/app/models/server/schema';

import { MediaItem } from '../reducers/media.reducer';

export const takePicture = createAction('[Media] Take Picture', props<{ height: number }>());
export const takePictureSuccess = createAction('[Media] Take Picture Success', props<{ item: MediaItem }>());
export const takePictureFailure = createAction('[Media] Take Picture Failure', props<{ error: any }>());

export const startAudioRecord = createAction('[Media] Start Record Audio');
export const startAudioRecordSuccess = createAction('[Media] Start Record Audio Success');
export const startAudioRecordFailure = createAction('[Media] Start Record Audio Failure', props<{ error: any }>());

export const stopAudioRecord = createAction('[Media] Stop Record Audio');
export const stopAudioRecordSuccess = createAction('[Media] Stop Record Audio Success', props<{ item: MediaItem }>());
export const stopAudioRecordFailure = createAction('[Media] Stop Record Audio Failure', props<{ error: any }>());

export const createUnsavedFiles = createAction(
  '[Media] Create Unsaved Files',
  props<{ workflowId: string; taskId: string; stepId: string }>(),
);
export const clearUnsaved = createAction('[Media] Clear Unsaved');
export const removeItem = createAction('[Media] Remove Item', props<{ id: string }>());

export const createFile = createAction(
  '[Media] Create File',
  props<{ input: FileCreateInput; blob: Blob; taskId: string; audio: boolean }>(),
);
export const createFileSuccess = createAction(
  '[Media] Create File Success',
  props<{ input: FileCreateInput; file: FileCreateMutation; blob: Blob; taskId: string; audio: boolean }>(),
);
export const createFileFailure = createAction('[Media] Create File Failure', props<{ error: any }>());

export const uploadFile = createAction('[Media] Upload File', props<{ file: FileCreateMutation; blob: Blob; audio: boolean }>());
export const uploadFileSuccess = createAction('[Media] Upload File Success', props<{ id: string; audio: boolean }>());
export const uploadFileFailure = createAction('[Media] Upload File Failure', props<{ error: any }>());

export const fetchFilesByWorkflow = createAction('[Media] Fetch Files By Workflow', props<{ workflowId: string; taskId: string }>());
export const fetchFilesByWorkflowSuccess = createAction(
  '[Media] Fetch Files By Workflow Success',
  props<{ fileUploadRecords: FileUploadRecord[]; taskId: string }>(),
);
export const fetchFilesByWorkflowFailure = createAction('[Media] Fetch Files By Workflow Failure', props<{ error: any }>());

export const startCameraPreview = createAction('[Media] Start Camera Preview', props<{ y: number; height: number }>());
export const startCameraPreviewSuccess = createAction('[Media] Start Camera Preview Success');
export const startCameraPreviewFailure = createAction('[Media] Start Camera Preview Failure', props<{ error: any }>());

export const stopCameraPreview = createAction('[Media] Stop Camera Preview');
export const stopCameraPreviewSuccess = createAction('[Media] Stop Camera Preview Success');
export const stopCameraPreviewFailure = createAction('[Media] Stop Camera Preview Failure', props<{ error: any }>());
