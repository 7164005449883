import { PermissionState } from '@capacitor/core';
import { createReducer, on } from '@ngrx/store';

import { AppActions, GeolocationActions } from '../actions';

export const featureKey = 'geolocation';

export interface State extends Partial<GeolocationCoordinates> {
  permission: PermissionState;
  enabled: boolean;
}

export const initialState: State = { enabled: false, permission: 'prompt' };

export const reducer = createReducer(
  initialState,
  // on(AppActions.applicationBegin, (state): State => initialState),

  on(GeolocationActions.watchPositionSuccess, (state, { coords }): State => ({ ...state, ...coords, enabled: true })),
  on(GeolocationActions.watchPositionFailure, (state): State => ({ ...state, enabled: false })),

  on(GeolocationActions.checkPermissionsSuccess, (state, { permission }): State => ({ ...state, permission })),
  on(GeolocationActions.checkPermissionsFailure, (state, { permission }): State => ({ ...state, permission })),
  on(GeolocationActions.requestPermissionsSuccess, (state, { permission }): State => ({ ...state, permission })),
  on(GeolocationActions.requestPermissionsFailure, (state, { permission }): State => ({ ...state, permission })),
);

export const getLatLng = ({ latitude, longitude }: State): google.maps.LatLngLiteral => ({ lat: latitude, lng: longitude });
export const getPermission = ({ permission }: State): PermissionState => permission;
export const getEnabled = ({ enabled }: State): boolean => enabled;

// Accepts two marker objects and returns the distance between them in miles.
// To use kilometers, set R = 6371.0710.
// Before applying the Haversine formula, the function converts each marker’s latitude and longitude points into radians.
export const distance = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
  if (!lat1 || !lng1 || !lat2 || !lng2) {
    return;
  }

  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = lat1 * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = lat2 * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (lng2 - lng1) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2),
      ),
    );
  return d;
};
