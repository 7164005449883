import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const { googleMapsKey } = environment;

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.jsonp(`https://maps.googleapis.com/maps/api/js?libraries=places&key=${googleMapsKey}`, 'callback');
  }
}
