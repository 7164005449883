import { Injectable } from '@angular/core';
import { CameraPreview } from '@capacitor-community/camera-preview';
import { defer, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  takePicture(width: number, height: number): Observable<{ value: string }> {
    return defer(() =>
      CameraPreview.capture({
        quality: 85,
        // width,
        // height,
      }),
    );
  }

  stopCameraPreview() {
    return CameraPreview.stop();
  }

  startCameraPreview(x: number, y: number, width: number, height: number) {
    return CameraPreview.start({
      // alpha: 1,
      position: 'rear',
      // camera: 'rear',
      disableExifHeaderStripping: true,
      // tapPhoto: false,
      // previewDrag: false,
      // tapFocus: true,
      toBack: true,
      x,
      y,
      width,
      height,
    });
  }
}
