import { Component, HostBinding } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { AppActions, GeolocationActions, MapActions, MediaActions, UserActions } from './state/actions';
import { selectCameraPreviewEnabled, selectUserEmail } from './state/reducers';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  email$ = this.store.select(selectUserEmail);
  cameraPreviewEnabled: boolean;
  @HostBinding('class') get class() {
    return this.cameraPreviewEnabled ? 'camera-transparent' : undefined;
  }
  cameraPreviewEnabled$ = this.store.select(selectCameraPreviewEnabled);

  constructor(private platform: Platform, private store: Store) {
    this.initializeApp();
    this.cameraPreviewEnabled$.subscribe((enabled) => {
      this.cameraPreviewEnabled = enabled;
      if (enabled) {
        document.body.classList.add('camera-transparent');
      } else {
        document.body.classList.remove('camera-transparent');
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.store.dispatch(MediaActions.clearUnsaved());
      this.store.dispatch(AppActions.applicationBegin());
      this.store.dispatch(MapActions.loadMap());
      this.store.dispatch(GeolocationActions.watchPosition());
      await SplashScreen.hide();
    });
  }

  logout() {
    this.store.dispatch(UserActions.logout());
  }
}
