import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { WorkflowStepContractType } from 'src/app/models/server/schema';
import { AudioService } from 'src/app/services/audio.service';
import { FileService } from 'src/app/services/file.service';
import { PhotoService } from 'src/app/services/photo.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

import { ErrorActions, MediaActions } from '../actions';
import { selectCurrentTaskId, selectMediaForTask } from '../reducers';

@Injectable()
export class MediaEffects {
  takePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.takePicture),
      concatLatestFrom((action) => this.store.select(selectCurrentTaskId)),
      exhaustMap(([{ height }, taskId]) =>
        this.photo.takePicture(this.platform.width(), height).pipe(
          map(({ value }) =>
            MediaActions.takePictureSuccess({
              item: {
                id: `${uuidv4()}.jpeg`,
                base64String: `data:image/jpeg;base64,${value}`,
                mimeType: 'image/jpeg',
                taskId,
                image: true,
                audio: false,
                uploadPending: true,
              },
            }),
          ),
          catchError((error) => of(MediaActions.takePictureFailure({ error }))),
        ),
      ),
    ),
  );

  takePictureFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.takePictureFailure),
      map(({ error }) => ErrorActions.handleError({ error, source: 'Camera' })),
    ),
  );

  startAudioRecord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MediaActions.startAudioRecord),
        exhaustMap(() => this.audio.startAudio()),
      ),
    { dispatch: false },
  );

  stopAudioRecord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MediaActions.stopAudioRecord),
        map(() => this.audio.stopAudio()),
      ),
    { dispatch: false },
  );

  startCameraPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.startCameraPreview),
      exhaustMap(({ y, height }) => this.photo.startCameraPreview(0, y, this.platform.width(), height)),
      map(() => MediaActions.startCameraPreviewSuccess()),
      catchError((msg) => of(MediaActions.startCameraPreviewFailure({ error: new Error(msg) }))),
    ),
  );

  startCameraPreviewFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.startCameraPreviewFailure),
      map(({ error }) => ErrorActions.handleError({ error, source: 'Camera Preview' })),
    ),
  );

  stopCameraPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.stopCameraPreview),
      exhaustMap(() => this.photo.stopCameraPreview()),
      map(() => MediaActions.stopCameraPreviewSuccess()),
      catchError((msg) => of(MediaActions.stopCameraPreviewFailure({ error: new Error(msg) }))),
    ),
  );

  stopCameraPreviewFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.stopCameraPreviewFailure),
      map(({ error }) => ErrorActions.handleError({ error, source: 'Camera Preview' })),
    ),
  );

  createUnsavedFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.createUnsavedFiles),
      concatLatestFrom((action) => this.store.select(selectMediaForTask)),
      tap(([{ workflowId, taskId, stepId }, items]) => console.log(items)),
      switchMap(([{ workflowId, taskId, stepId }, items]) =>
        items.map((item) =>
          MediaActions.createFile({
            input: {
              name: item.id,
              source: `/maintain-mobile/${environment.glidecloudTenantId}`,
              type: item.mimeType,
              convert: item.mimeType.includes('webm'),
              transcribe: item.audio,
              translate: false,
              workflowId,
              workflowStepContracts: [{ stepId, contractName: 'UploadMediaFiles', contract_type: WorkflowStepContractType.Completion }],
            },
            blob: this.file.base64ToBlob(item.base64String, item.mimeType),
            taskId,
            audio: item.audio,
          }),
        ),
      ),
    ),
  );

  createFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.createFile),
      mergeMap(({ blob, input, taskId, audio }) =>
        this.file.create(input).pipe(
          map((file) => MediaActions.createFileSuccess({ blob, file, input, taskId, audio })),
          catchError((error) => of(MediaActions.createFileFailure({ error }))),
        ),
      ),
    ),
  );

  createFileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.createFileSuccess),
      map(({ blob, file, audio }) => MediaActions.uploadFile({ blob, file, audio })),
    ),
  );

  createFileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.createFileFailure),
      map(({ error }) => ErrorActions.handleError({ error, source: 'File Create' })),
    ),
  );

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.uploadFile),
      mergeMap(({ file, blob, audio }) =>
        this.file.upload(file, blob).pipe(
          map(() => MediaActions.uploadFileSuccess({ id: file.fileCreate.name, audio })),
          catchError((error) => of(MediaActions.uploadFileFailure({ error }))),
        ),
      ),
    ),
  );

  uploadFileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.uploadFileFailure),
      map(({ error }) => ErrorActions.handleError({ error, source: 'File Upload' })),
    ),
  );

  fetchFilesByWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.fetchFilesByWorkflow),
      concatMap(({ taskId, workflowId }) =>
        this.file.filesByWorkflow(workflowId).pipe(
          map((filesByWorkflow) => MediaActions.fetchFilesByWorkflowSuccess({ fileUploadRecords: filesByWorkflow, taskId })),
          catchError((error) => of(MediaActions.fetchFilesByWorkflowFailure({ error }))),
        ),
      ),
    ),
  );

  fetchFilesByWorkflowFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.fetchFilesByWorkflowFailure),
      map(({ error }) => ErrorActions.handleError({ error, source: 'Fetch Files By Workflow' })),
    ),
  );

  constructor(
    private actions$: Actions,
    private audio: AudioService,
    private file: FileService,
    private platform: Platform,
    private photo: PhotoService,
    private store: Store,
  ) {}
}
