import { createAction, props } from '@ngrx/store';

export const loadMap = createAction('[Map] Load Google Maps');
export const loadMapSuccess = createAction('[Map] Load Google Maps Success');
export const loadMapFailure = createAction('[Map] Load Google Maps Failure', props<{ error: any }>());

export const setSitesZoom = createAction('[Map] Set Sites Zoom', props<{ sitesZoom: number }>());
export const setTasksZoom = createAction('[Map] Set Tasks Zoom', props<{ tasksZoom: number }>());
export const setCurrentPosition = createAction('[Map] Set Current Position', props<{ currentPosition: google.maps.LatLngLiteral }>());

export const recenterSitesPosition = createAction('[Map] Recenter Sites Position');
export const recenterTasksPosition = createAction('[Map] Recenter Tasks Position');