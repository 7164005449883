import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ServiceLineCreateGQL, ServiceLineInput, ServiceLineRetrieveGQL, ServiceLinesListGQL } from '../models/server/schema';

@Injectable({
  providedIn: 'root',
})
export class ServiceLineService {
  constructor(
    private serviceLinesListGQL: ServiceLinesListGQL,
    private serviceLineRetrieveGQL: ServiceLineRetrieveGQL,
    private serviceLineCreateGQL: ServiceLineCreateGQL,
  ) {}

  create(input: ServiceLineInput) {
    return this.serviceLineCreateGQL.mutate({ input }).pipe(map(({ data: { serviceLineCreate } }) => serviceLineCreate));
  }

  list() {
    return this.serviceLinesListGQL.fetch().pipe(map(({ data: { serviceLinesList } }) => serviceLinesList));
  }

  retrieve(id: string) {
    return this.serviceLineRetrieveGQL.fetch({ id }).pipe(map(({ data: { serviceLineRetrieve } }) => serviceLineRetrieve));
  }
}
