import { createAction, props } from '@ngrx/store';
import { UserAttributes } from 'src/app/models/client/user.model';
import { User, UserRole } from 'src/app/models/server/schema';

export const login = createAction('[User] Login', props<{ email: string; password: string }>());
export const loginSuccess = createAction('[User] Login Success', props<{ user: UserAttributes | User }>());
export const loginFailure = createAction('[User] Login Failure', props<{ error: any }>());

export const registerUser = createAction(
  '[User] Register User',
  props<{ firstName: string; lastName: string; role: UserRole; phone: string; email: string; password: string }>(),
);
export const registerUserSuccess = createAction(
  '[User] Register User Success',
  props<{ user: UserAttributes | User; password: string }>(),
);
export const registerUserFailure = createAction('[User] Register User Failure', props<{ error: any; warning?: boolean }>());

export const confirmRegistration = createAction(
  '[User] Confirm Registration',
  props<{ code: string; email: string; password: string }>(),
);
export const confirmRegistrationSuccess = createAction(
  '[User] Confirm Registration Success',
  props<{ email: string; password: string }>(),
);
export const confirmRegistrationFailure = createAction('[User] Confirm Registration Failure', props<{ error }>());

export const resendConfirmation = createAction('[User] Resend Confirmation', props<{ email: string }>());
export const resendConfirmationSuccess = createAction('[User] Resend Confirmation Success');
export const resendConfirmationFailure = createAction('[User] Resend Confirmation Failure', props<{ error }>());

export const forgotPassword = createAction('[User] Forgot Password', props<{ email: string }>());
export const forgotPasswordSuccess = createAction('[User] Forgot Password Success');
export const forgotPasswordFailure = createAction('[User] Forgot Password Failure', props<{ error }>());

export const verifyPasswordReset = createAction(
  '[User] Verify Password Reset',
  props<{ code: string; email: string; password: string }>(),
);
export const verifyPasswordResetSuccess = createAction('[User] Verify Password Reset Success');
export const verifyPasswordResetFailure = createAction('[User] Verify Password Reset Failure', props<{ error }>());

export const logout = createAction('[User] Logout');
