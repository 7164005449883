import { ErrorHandler, Injectable, InjectionToken, Injector } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
// import * as LogRocket from 'logrocket';
// import * as Rollbar from 'rollbar';
// import { environment } from 'src/environments/environment';

// export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  // rollbar: Rollbar;

  constructor(private alert: AlertController, private injector: Injector, private toast: ToastController) {
    // if (environment.production) {
    //   this.rollbar = this.injector.get<Rollbar>(RollbarService);
    //   LogRocket.getSessionURL((sessionURL) => {
    //     this.rollbar.configure({
    //       transform: (obj) => {
    //         (obj as any).sessionURL = sessionURL;
    //       },
    //     });
    //   });
    // }
  }

  debug(error: any) {
    // if (environment.production) {
    // this.rollbar.debug(error);
    // }
  }

  async showAlertMessage(message: string) {
    const alert = await this.alert.create({
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  async showWarning(error: any, source?: string): Promise<void> {
    const message = source ? `${source}: ${error.message}` : error.message;
    const toast = await this.toast.create({ message, color: 'warning', duration: 5000 });
    await toast.present();
  }

  async handleError(error: any, source?: string): Promise<void> {
    console.error(error);
    // if (environment.production) {
    // this.rollbar.error(error.originalError || error);
    // }
    const message = source ? `${source}: ${error.message}` : error.message;
    const toast = await this.toast.create({ message, color: 'danger', duration: 5000 });
    await toast.present();
  }
}
