import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { SiteCreateGQL, SiteInput, SiteRetrieveGQL, SitesListGQL, SiteUpdateGQL } from '../models/server/schema';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(
    private siteCreateGQL: SiteCreateGQL,
    private sitesListGQL: SitesListGQL,
    private siteRetrieveGQL: SiteRetrieveGQL,
    private siteUpdateGQL: SiteUpdateGQL,
  ) {}

  create(input: SiteInput) {
    return this.siteCreateGQL.mutate({ input }).pipe(map(({ data: { siteCreate } }) => siteCreate));
  }

  list() {
    return this.sitesListGQL.fetch(undefined, { fetchPolicy: 'network-only' }).pipe(map(({ data: { sitesList } }) => sitesList));
  }

  retreive(id: string) {
    return this.siteRetrieveGQL.fetch({ id }, { fetchPolicy: 'network-only' }).pipe(map(({ data: { siteRetrieve } }) => siteRetrieve));
  }

  update(id: string, input: SiteInput) {
    return this.siteUpdateGQL.mutate({ id, input }).pipe(map(({ data: { siteUpdate } }) => siteUpdate));
  }
}
