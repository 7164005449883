import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { MediaActions, TaskActions, UserActions } from '../actions';

export const featureKey = 'media';

export interface MediaItem {
  id: string;
  taskId: string;
  base64String?: string;
  url?: string;
  mimeType: string;
  image: boolean;
  audio: boolean;
  uploadPending?: boolean;
}

export interface State extends EntityState<MediaItem> {
  cameraPreviewEnabled?: boolean;
}

export const adapter: EntityAdapter<MediaItem> = createEntityAdapter<MediaItem>();
export const initialState: State = adapter.getInitialState();

export const entityReducer = createReducer(
  initialState,

  on(MediaActions.takePictureSuccess, (state, { item }): State => adapter.upsertOne(item, state)),
  on(MediaActions.stopAudioRecordSuccess, (state, { item }): State => adapter.upsertOne(item, state)),

  on(MediaActions.clearUnsaved, (state): State => adapter.removeMany((item) => item.uploadPending, state)),

  on(MediaActions.removeItem, (state, { id }) => adapter.removeOne(id, state)),
  on(UserActions.logout, (state): State => adapter.removeAll({ ...state, ...initialState })),

  on(MediaActions.uploadFileSuccess, (state, { audio, id }) =>
    audio ? adapter.removeOne(id, state) : adapter.updateOne({ id, changes: { uploadPending: null } }, state),
  ),

  on(MediaActions.fetchFilesByWorkflowSuccess, (state, { fileUploadRecords, taskId }) =>
    adapter.upsertMany(
      fileUploadRecords.map(({ name, url, type }) => ({
        id: name,
        url,
        taskId,
        mimeType: type,
        image: type.startsWith('image'),
        audio: type.startsWith('audio'),
      })),
      state,
    ),
  ),

  on(TaskActions.deleteTask, (state, { id }) => adapter.removeMany((item) => item.taskId === id, state)),

  on(TaskActions.createTaskSuccess, (state, { task, tmpTaskId }) =>
    adapter.updateMany(
      Object.values(state.entities)
        .filter((item) => item.taskId === tmpTaskId)
        .map((item) => ({ id: item.id, changes: { taskId: task.id } })),
      state,
    ),
  ),

  on(MediaActions.startCameraPreviewSuccess, (state) => ({ ...state, cameraPreviewEnabled: true })),
  on(MediaActions.startCameraPreviewFailure, (state) => ({ ...state, cameraPreviewEnabled: false })),
  on(MediaActions.stopCameraPreviewSuccess, (state) => ({ ...state, cameraPreviewEnabled: false })),
  on(MediaActions.stopCameraPreviewFailure, (state) => ({ ...state, cameraPreviewEnabled: true })),
);

export const reducer = (state: State | undefined, action: Action) => entityReducer(state, action);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getCameraPreviewEnabled = ({ cameraPreviewEnabled }: State): boolean => cameraPreviewEnabled;
export const getMediaForTask = (items: MediaItem[], taskId: string) => items.filter((i) => i.taskId === taskId);
export const getImagesForTask = (items: MediaItem[], taskId: string) => items.filter((i) => i.taskId === taskId && i.image);
