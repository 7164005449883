import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { map } from 'rxjs/operators';
import { Task } from 'src/app/models/client/task.model';

import { TaskCreateGQL, TaskDeleteGQL, TaskInput, TaskRetrieveGQL, TasksListGQL, TaskUpdateGQL } from '../models/server/schema';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(
    private taskCreateGQL: TaskCreateGQL,
    private taskDeleteGQL: TaskDeleteGQL,
    private taskListGQL: TasksListGQL,
    private taskRetrieveGQL: TaskRetrieveGQL,
    private taskUpdateGQL: TaskUpdateGQL,
  ) {}

  create(input: TaskInput) {
    return this.taskCreateGQL.mutate({ input }).pipe(map(({ data: { taskCreate } }) => taskCreate));
  }

  delete(id: string) {
    return this.taskDeleteGQL.mutate({ id }).pipe(map(({ data: { taskDelete } }) => taskDelete));
  }

  list() {
    return this.taskListGQL.fetch(undefined, { fetchPolicy: 'network-only' }).pipe(map(({ data: { tasksList } }) => tasksList));
  }

  retreive(id: string) {
    return this.taskRetrieveGQL.fetch({ id }, { fetchPolicy: 'network-only' }).pipe(map(({ data: { taskRetrieve } }) => taskRetrieve));
  }

  update(update: Update<Task>) {
    return this.taskUpdateGQL
      .mutate({ id: update.id as string, input: update.changes })
      .pipe(map(({ data: { taskUpdate } }) => taskUpdate));
  }
}
