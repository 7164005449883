import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { GeolocationService } from 'src/app/services/geolocation.service';

import { ErrorActions, GeolocationActions } from '../actions';
import { selectGeolocationPermission } from '../reducers';

@Injectable()
export class GeolocationEffects {
  isDesktop: boolean = this.platform.is('desktop');

  checkPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.checkPermissions),
      exhaustMap(() => this.geolocation.checkPermissions()),
      map((permission) =>
        permission === 'granted'
          ? GeolocationActions.checkPermissionsSuccess({ permission })
          : GeolocationActions.checkPermissionsFailure({ permission }),
      ),
    ),
  );

  checkPermissionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.checkPermissionsSuccess),
      map(() => GeolocationActions.watchPosition()),
    ),
  );

  checkPermissionsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.checkPermissionsFailure),
      map(({ permission }) =>
        permission === 'denied'
          ? ErrorActions.showAlertMessage({ message: 'Geolocation permissions denied. Please update settings.' })
          : GeolocationActions.requestPermissions(),
      ),
    ),
  );

  requestPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.requestPermissions),
      exhaustMap(() => this.geolocation.requestPermissions()),
      map((permission) =>
        permission === 'granted'
          ? GeolocationActions.requestPermissionsSuccess({ permission })
          : GeolocationActions.requestPermissionsFailure({ permission }),
      ),
    ),
  );

  requestPermissionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.requestPermissionsSuccess),
      map(() => GeolocationActions.watchPosition()),
    ),
  );

  requestPermissionsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.requestPermissionsFailure),
      map(() => ErrorActions.showAlertMessage({ message: 'Geolocation permissions denied. Please update settings.' })),
    ),
  );

  watchPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.watchPosition),
      concatLatestFrom(() => this.store.select(selectGeolocationPermission)),
      exhaustMap(([action, permission]) =>
        permission === 'granted'
          ? this.geolocation.watchPosition().pipe(
              map((pos) =>
                Array.isArray(pos)
                  ? GeolocationActions.watchPositionFailure({ error: pos[1] })
                  : GeolocationActions.watchPositionSuccess({
                      coords: this.isDesktop ? this.flattenPrototype(pos.coords) : pos.coords,
                    }),
              ),
              catchError((error) => of(GeolocationActions.watchPositionFailure({ error }))),
            )
          : of(GeolocationActions.checkPermissions()),
      ),
    ),
  );

  watchPositionFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.watchPositionFailure),
      map(({ error }) => ErrorActions.handleError({ error, source: 'Geolocation' })),
    ),
  );

  constructor(private actions$: Actions, private geolocation: GeolocationService, private platform: Platform, private store: Store) {}

  // brings keys higher in the prototype chain to the lowest level
  // turns out, using the spread operator only works on the lowest part of the prototype chain
  flattenPrototype<T>(obj: T): T {
    console.log(obj);
    return Object.keys(Object.getPrototypeOf(obj)).reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {} as any);
  }
}
