import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Position, Site } from 'src/app/models/server/schema';

import { AppActions, UserActions } from '../actions';
import * as SiteActions from '../actions/site.actions';

export const featureKey = 'site';

// export interface SelectedSite extends Site {
// selected?: boolean;
// }

export interface State extends EntityState<Site> {
  // additional entities state properties
  currentEntityId: string | null;
  listMode: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Site> = createEntityAdapter<Site>({
  selectId: (site: Site): string => site.id,
  sortComparer: (a: Site, b: Site): number => a.name.localeCompare(b.name),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  currentEntityId: null,
  listMode: true,
  loading: false,
});

const entityReducer = createReducer(
  initialState,
  on(AppActions.applicationBegin, (state): State => ({ ...state, loading: false })),

  on(SiteActions.fetchSites, (state): State => ({ ...state, loading: true })),
  on(SiteActions.fetchSitesSuccess, (state, { sites }): State => adapter.setAll(sites, { ...state, loading: false })),
  on(SiteActions.fetchSitesFailure, (state): State => ({ ...state, loading: false })),

  on(SiteActions.fetchSite, (state): State => ({ ...state, loading: true })),
  on(SiteActions.fetchSiteSuccess, (state, { site }): State => adapter.setOne(site, { ...state, loading: false })),
  on(SiteActions.fetchSiteFailure, (state): State => ({ ...state, loading: false })),

  on(SiteActions.createSite, (state): State => ({ ...state, loading: true })),
  on(SiteActions.createSiteSuccess, (state, { site }): State => adapter.upsertOne(site, { ...state, loading: false })),
  on(SiteActions.createSiteFailure, (state): State => ({ ...state, loading: false })),

  on(SiteActions.addSite, (state, { site }): State => adapter.addOne(site, state)),
  on(SiteActions.setSite, (state, { site }): State => adapter.setOne(site, state)),
  on(SiteActions.upsertSite, (state, { site }): State => adapter.upsertOne(site, state)),

  on(SiteActions.addSites, (state, { sites }): State => adapter.addMany(sites, state)),
  on(SiteActions.upsertSites, (state, { sites }): State => adapter.upsertMany(sites, state)),

  on(SiteActions.updateSite, (state, { update }): State => adapter.updateOne(update, state)),
  on(SiteActions.updateSites, (state, { updates }): State => adapter.updateMany(updates, state)),

  on(SiteActions.mapSite, (state, { entityMap }): State => adapter.mapOne(entityMap, state)),
  on(SiteActions.mapSites, (state, { entityMap }): State => adapter.map(entityMap, state)),

  on(SiteActions.deleteSite, (state, { id }): State => adapter.removeOne(id, state)),
  on(SiteActions.deleteSites, (state, { ids }): State => adapter.removeMany(ids, state)),
  on(SiteActions.deleteSitesByPredicate, (state, { predicate }): State => adapter.removeMany(predicate, state)),

  on(SiteActions.loadSites, (state, { sites }): State => adapter.setAll(sites, state)),

  on(SiteActions.clearSites, (state): State => adapter.removeAll({ ...state, selectedSiteId: null })),
  on(UserActions.logout, (state): State => adapter.removeAll({ ...state, ...initialState })),

  on(SiteActions.setCurrentSite, (state, { id }): State => ({ ...state, currentEntityId: id })),
  on(SiteActions.clearCurrentSite, (state): State => ({ ...state, currentEntityId: null })),

  on(
    SiteActions.toggleListMode,
    (state): State => ({ ...state, listMode: !state.listMode, ...(!state.listMode ? { currentEntityId: undefined } : {}) }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return entityReducer(state, action);
}

// get the selectors
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getCurrentEntityId = (state: State): string => state.currentEntityId;

export const getIfListMode = (state: State): boolean => state.listMode;
export const getListIcon = (state: State): 'map-outline' | 'list-outline' => (state.listMode ? 'map-outline' : 'list-outline');
export const getFirst = (sites: Site[]): Site => sites[0];
export const getById = (sites: Dictionary<Site>, id: string): Site => sites[id];

export const getName = (site: Site): string => site?.name;
export const getPosition = (site: Site): Position => site?.position;
export const getIfDisplayDrawer = (site: Site, listMode: boolean): boolean => !!site && !listMode;

export const getLoading = (state: State): boolean => state.loading;

export const getSortedByDistance =
  (distance: (lat1: number, lng1: number, lat2: number, lng2: number) => number) =>
  (sites: Site[], currentLocation: google.maps.LatLngLiteral): Site[] =>
    sites.sort(
      (a: Site, b: Site) =>
        distance(a.position.latitude, a.position.longitude, currentLocation.lat, currentLocation.lng) -
        distance(b.position.latitude, b.position.longitude, currentLocation.lat, currentLocation.lng),
    );
