import { EntityMap, EntityMapOne, Predicate, Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ServiceLine, ServiceLineInput } from 'src/app/models/server/schema';

export const fetchServiceLines = createAction('[ServiceLines] Fetch ServiceLines');
export const fetchServiceLinesSuccess = createAction('[ServiceLines] Fetch ServiceLines Success', props<{ serviceLines: ServiceLine[] }>());
export const fetchServiceLinesFailure = createAction('[ServiceLines] Fetch ServiceLines Failure', props<{ error: any }>());

export const createServiceLine = createAction('[ServiceLines] Create ServiceLine', props<{ input: ServiceLineInput }>());
export const createServiceLineSuccess = createAction('[ServiceLines] Create ServiceLine Success', props<{ serviceLine: ServiceLine }>());
export const createServiceLineFailure = createAction('[ServiceLines] Create ServiceLine Failure', props<{ error: any }>());

export const loadServiceLines = createAction('[ServiceLines] Load ServiceLines', props<{ serviceLines: ServiceLine[] }>());
export const addServiceLine = createAction('[ServiceLines] Add ServiceLine', props<{ serviceLine: ServiceLine }>());
export const setServiceLine = createAction('[ServiceLines] Set ServiceLine', props<{ serviceLine: ServiceLine }>());
export const upsertServiceLine = createAction('[ServiceLines] Upsert ServiceLine', props<{ serviceLine: ServiceLine }>());
export const addServiceLines = createAction('[ServiceLines] Add ServiceLines', props<{ serviceLines: ServiceLine[] }>());
export const upsertServiceLines = createAction('[ServiceLines] Upsert ServiceLines', props<{ serviceLines: ServiceLine[] }>());
export const updateServiceLine = createAction('[ServiceLines] Update ServiceLine', props<{ update: Update<ServiceLine> }>());
export const updateServiceLines = createAction('[ServiceLines] Update ServiceLines', props<{ updates: Update<ServiceLine>[] }>());
export const mapServiceLine = createAction('[ServiceLines] Map ServiceLine', props<{ entityMap: EntityMapOne<ServiceLine> }>());
export const mapServiceLines = createAction('[ServiceLines] Map ServiceLines', props<{ entityMap: EntityMap<ServiceLine> }>());
export const deleteServiceLine = createAction('[ServiceLines] Delete ServiceLine', props<{ id: string }>());
export const deleteServiceLines = createAction('[ServiceLines] Delete ServiceLines', props<{ ids: string[] }>());
export const deleteServiceLinesByPredicate = createAction(
  '[ServiceLines] Delete ServiceLines By Predicate',
  props<{ predicate: Predicate<ServiceLine> }>(),
);
export const clearServiceLines = createAction('[ServiceLines] Clear ServiceLines');

export const setCurrentServiceLine = createAction('[ServiceLines] Set Current ServiceLine', props<{ id: string }>());
export const clearCurrentServiceLine = createAction('[ServiceLines] Clear Current ServiceLine');
