import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ServiceLine } from 'src/app/models/server/schema';

import { AppActions, UserActions } from '../actions';
import * as ServiceLineActions from '../actions/service-line.actions';

export const featureKey = 'service-line';

export interface State extends EntityState<ServiceLine> {
  // additional entities state properties
  currentServiceLineId: string | null;
  loading: boolean;
}

export const adapter: EntityAdapter<ServiceLine> = createEntityAdapter<ServiceLine>({
  selectId: (serviceLine: ServiceLine): string => serviceLine.id,
  sortComparer: (a: ServiceLine, b: ServiceLine): number => a.name.localeCompare(b.name),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  currentServiceLineId: null,
  loading: false,
});

const serviceLineReducer = createReducer(
  initialState,
  on(AppActions.applicationBegin, (state): State => ({ ...state, loading: false })),

  on(ServiceLineActions.fetchServiceLines, (state): State => ({ ...state, loading: true })),
  on(
    ServiceLineActions.fetchServiceLinesSuccess,
    (state, { serviceLines }): State => adapter.setAll(serviceLines, { ...state, loading: false }),
  ),
  on(ServiceLineActions.fetchServiceLinesFailure, (state): State => ({ ...state, loading: false })),

  on(ServiceLineActions.createServiceLine, (state): State => ({ ...state, loading: true })),
  on(
    ServiceLineActions.createServiceLineSuccess,
    (state, { serviceLine }): State => adapter.upsertOne(serviceLine, { ...state, loading: false }),
  ),
  on(ServiceLineActions.createServiceLineFailure, (state): State => ({ ...state, loading: false })),

  on(ServiceLineActions.addServiceLine, (state, { serviceLine }): State => adapter.addOne(serviceLine, state)),
  on(ServiceLineActions.setServiceLine, (state, { serviceLine }): State => adapter.setOne(serviceLine, state)),
  on(ServiceLineActions.upsertServiceLine, (state, { serviceLine }): State => adapter.upsertOne(serviceLine, state)),

  on(ServiceLineActions.addServiceLines, (state, { serviceLines }): State => adapter.addMany(serviceLines, state)),
  on(ServiceLineActions.upsertServiceLines, (state, { serviceLines }): State => adapter.upsertMany(serviceLines, state)),

  on(ServiceLineActions.updateServiceLine, (state, { update }): State => adapter.updateOne(update, state)),
  on(ServiceLineActions.updateServiceLines, (state, { updates }): State => adapter.updateMany(updates, state)),

  on(ServiceLineActions.mapServiceLine, (state, { entityMap }): State => adapter.mapOne(entityMap, state)),
  on(ServiceLineActions.mapServiceLines, (state, { entityMap }): State => adapter.map(entityMap, state)),

  on(ServiceLineActions.deleteServiceLine, (state, { id }): State => adapter.removeOne(id, state)),
  on(ServiceLineActions.deleteServiceLines, (state, { ids }): State => adapter.removeMany(ids, state)),
  on(ServiceLineActions.deleteServiceLinesByPredicate, (state, { predicate }): State => adapter.removeMany(predicate, state)),

  on(ServiceLineActions.loadServiceLines, (state, { serviceLines }): State => adapter.setAll(serviceLines, state)),

  on(ServiceLineActions.clearServiceLines, (state): State => adapter.removeAll({ ...state, currentServiceLineId: null })),
  on(UserActions.logout, (state): State => adapter.removeAll({ ...state, ...initialState })),

  on(ServiceLineActions.setCurrentServiceLine, (state, { id }): State => ({ ...state, currentServiceLineId: id })),
  on(ServiceLineActions.clearCurrentServiceLine, (state): State => ({ ...state, currentServiceLineId: null })),
);

export function reducer(state: State | undefined, action: Action) {
  return serviceLineReducer(state, action);
}

// export const getSelectedServiceLineId = (state: State) : State => state.currentServiceLineId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of ServiceLine ids
export const selectServiceLineIds = selectIds;

// select the dictionary of ServiceLine entities
export const selectServiceLineEntities = selectEntities;

// select the array of ServiceLines
export const selectAllServiceLines = selectAll;

// select the total ServiceLine count
export const selectServiceLineTotal = selectTotal;
