import { EntityMap, EntityMapOne, Predicate, Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Task } from 'src/app/models/client/task.model';
import { TaskInput } from 'src/app/models/server/schema';

export const fetchTasks = createAction('[Tasks] Fetch Tasks');
export const fetchTasksSuccess = createAction('[Tasks] Fetch Tasks Success', props<{ tasks: Task[] }>());
export const fetchTasksFailure = createAction('[Tasks] Fetch Tasks Failure', props<{ error: any }>());

export const fetchTask = createAction('[Tasks] Fetch Task', props<{ id: string }>());
export const fetchTaskSuccess = createAction('[Tasks] Fetch Task Success', props<{ task: Task }>());
export const fetchTaskFailure = createAction('[Tasks] Fetch Task Failure', props<{ error: any }>());

export const updateTask = createAction('[Tasks] Update Task', props<{ update: Update<Task> }>());
export const updateTaskSuccess = createAction('[Tasks] Update Task Success', props<{ task: Task }>());
export const updateTaskFailure = createAction('[Tasks] Update Task Failure', props<{ error: any }>());

export const createTask = createAction('[Tasks] Create Task', props<{ input: TaskInput }>());
export const createTaskSuccess = createAction('[Tasks] Create Task Success', props<{ task: Task; tmpTaskId: string }>());
export const createTaskFailure = createAction('[Tasks] Create Task Failure', props<{ error: any }>());

export const loadTasks = createAction('[Tasks] Load Tasks', props<{ tasks: Task[] }>());
export const addTask = createAction('[Tasks] Add Task', props<{ task: Task }>());
export const setTask = createAction('[Tasks] Set Task', props<{ task: Task }>());
export const upsertTask = createAction('[Tasks] Upsert Task', props<{ task: Task }>());
export const addTasks = createAction('[Tasks] Add Tasks', props<{ tasks: Task[] }>());
export const upsertTasks = createAction('[Tasks] Upsert Tasks', props<{ tasks: Task[] }>());

export const updateTasks = createAction('[Tasks] Update Tasks', props<{ updates: Update<Task>[] }>());
export const mapTask = createAction('[Tasks] Map Task', props<{ entityMap: EntityMapOne<Task> }>());
export const mapTasks = createAction('[Tasks] Map Tasks', props<{ entityMap: EntityMap<Task> }>());

export const deleteTask = createAction('[Tasks] Delete Task', props<{ id: string }>());
export const deleteTaskSuccess = createAction('[Tasks] Delete Task Success', props<{ id: string }>());
export const deleteTaskFailure = createAction('[Tasks] Delete Task Failure', props<{ error: any }>());

export const deleteTasks = createAction('[Tasks] Delete Tasks', props<{ ids: string[] }>());
export const deleteTasksByPredicate = createAction('[Tasks] Delete Tasks By Predicate', props<{ predicate: Predicate<Task> }>());
export const clearTasks = createAction('[Tasks] Clear Tasks');

export const toggleListMode = createAction('[Tasks] Toggle List Mode');

export const setCurrentTask = createAction('[Tasks] Set Current Task', props<{ id: string }>());
export const clearCurrentTask = createAction('[Tasks] Clear Current Task');

export const navigateToTask = createAction('[Tasks] Navigate to Task', props<{ id: string; create?: boolean }>());
export const navigateToTaskList = createAction('[Tasks] Navigate to Task List', props<{ siteId: string }>());
