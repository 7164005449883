import { PermissionState } from '@capacitor/core';
import { createAction, props } from '@ngrx/store';

export const checkPermissions = createAction('[Geolocation] Check Permissions');
export const checkPermissionsSuccess = createAction('[Geolocation] Check Permissions Success', props<{ permission: PermissionState }>());
export const checkPermissionsFailure = createAction('[Geolocation] Check Permissions Failure', props<{ permission: PermissionState }>());

export const requestPermissions = createAction('[Geolocation] Request Permissions');
export const requestPermissionsSuccess = createAction(
  '[Geolocation] Request Permissions Success',
  props<{ permission: PermissionState }>(),
);
export const requestPermissionsFailure = createAction(
  '[Geolocation] Request Permissions Failure',
  props<{ permission: PermissionState }>(),
);

export const watchPosition = createAction('[Geolocation] Watch Position');
export const watchPositionFailure = createAction('[Geolocation] Watch Position Failure', props<{ error: any }>());
export const watchPositionSuccess = createAction('[Geolocation] Watch Position Success', props<{ coords: GeolocationCoordinates }>());
