import { PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';

export class CountryPhone {
  phoneUtil = PhoneNumberUtil.getInstance();

  // Country phone number type to use as a placeholder.
  countryExampleNumber = this.phoneUtil.getExampleNumberForType(this.iso, PhoneNumberType.MOBILE);
  countryCode = '+' + this.countryExampleNumber.getCountryCode();

  // Format the placeholder phone number
  exampleNumberFormatted = this.phoneUtil.format(this.countryExampleNumber, PhoneNumberFormat.NATIONAL);

  constructor(public iso: string, public name: string) {}
}
