import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Socket as PhoenixSocket } from 'phoenix';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PhoenixService {
  socket: PhoenixSocket;

  constructor(private store: Store, private toast: ToastController) {}

  async connect() {
    console.log('phoenix connect()');
    this.disconnect();

    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

    this.socket = new PhoenixSocket(`${environment.wssHost}/workflows/socket`, {
      params: { tenant_id: environment.glidecloudTenantId, token },
    });
    this.socket.connect();
  }

  disconnect() {
    console.log('phoenix connect()');
    this.socket?.disconnect();
    this.socket = undefined;
  }

  async taskSubscribe(workflowId: string) {
    if (this.socket.connectionState() !== 'open') {
      await this.connect();
    }
    const channel = this.socket.channel(`workflow_trees:${workflowId}`, { recursive: true, mode: 'all' });

    channel.on('update', ({ workflow_tree }: { workflow_tree: any }) => {
      // console.log('update', workflow_tree);
      // if a step, not the whole tree
      // if (workflow_tree?.parent_id) {
      // this.store.dispatch(WorkflowActions.getInterviewStep({ id: workflow_tree.id }));
      // }
    });

    // The WorkflowTree is pushed on-success for hydrating client state.
    // This is guaranteed to arrive before the first "update" event.
    channel
      .join()
      .receive('ok', (resp) => console.log('ok', resp))
      .receive('error', async (err) => {
        console.log('error', err);
        const toast = await this.toast.create({ message: err.message, duration: 5000, color: 'danger' });
        await toast.present();
      })
      .receive('timeout', async (resp) => {
        console.log('timeout', resp);
        const toast = await this.toast.create({ message: resp.message, duration: 5000, color: 'danger' });
        await toast.present();
      });
  }
}
