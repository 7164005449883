import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { RouterActions } from '../actions';

@Injectable()
export class RouterEffects {
  back$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.back),
        map(() => this.nav.back()),
      ),
    { dispatch: false },
  );

  pop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.pop),
        map(() => this.nav.pop()),
      ),
    { dispatch: false },
  );

  navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.navigateForward),
        map(({ url, options }) => this.nav.navigateForward(url, options)),
      ),
    { dispatch: false },
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.navigateBack),
        map(({ url, options }) => this.nav.navigateBack(url, options)),
      ),
    { dispatch: false },
  );

  navigateRoot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.navigateRoot),
        map(({ url, options }) => this.nav.navigateRoot(url, options)),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private nav: NavController) {}
}
