import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { TaskService } from 'src/app/services/task.service';

import { ErrorActions, MediaActions, RouterActions, SiteActions, TaskActions } from '../actions';
import { selectCurrentSiteId, selectCurrentTaskId } from '../reducers';

@Injectable()
export class TaskEffects {
  fetchTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.fetchTasks),
      exhaustMap(() =>
        this.task.list().pipe(
          map(({ items }) => TaskActions.fetchTasksSuccess({ tasks: items })),
          catchError((error) => of(TaskActions.fetchTasksFailure({ error }))),
        ),
      ),
    ),
  );

  // fetchTasksSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(TaskActions.fetchTasksSuccess),
  //     switchMap(({ tasks }) =>
  //       tasks
  //         .filter((t) => t.workflow?.id)
  //         .map(({ id, workflow }) => MediaActions.fetchFilesByWorkflow({ workflowId: workflow.id, taskId: id })),
  //     ),
  //   ),
  // );

  fetchTasksFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.fetchTasksFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  fetchTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.fetchTask),
      exhaustMap(({ id }) =>
        this.task.retreive(id).pipe(
          map((task) => TaskActions.fetchTaskSuccess({ task })),
          catchError((error) => of(TaskActions.fetchTaskFailure({ error }))),
        ),
      ),
    ),
  );

  fetchTaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.fetchTaskSuccess),
      map(({ task }) => MediaActions.fetchFilesByWorkflow({ workflowId: task.workflow?.id, taskId: task.id })),
    ),
  );

  fetchTaskFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.fetchTaskFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  updateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.updateTask),
      exhaustMap(({ update }) =>
        this.task.update(update).pipe(
          map(({ task }) => TaskActions.updateTaskSuccess({ task })),
          catchError((error) => of(TaskActions.updateTaskFailure({ error }))),
        ),
      ),
    ),
  );

  updateTaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.updateTaskSuccess),
      map(({ task }) => MediaActions.fetchFilesByWorkflow({ workflowId: task.workflow?.id, taskId: task.id })),
    ),
  );

  updateTaskFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.updateTaskFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  deleteTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.deleteTask),
      exhaustMap(({ id }) =>
        this.task.delete(id).pipe(
          map(() => TaskActions.deleteTaskSuccess({ id })),
          catchError((error) => of(TaskActions.deleteTaskFailure({ error }))),
        ),
      ),
    ),
  );

  deleteTaskFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.deleteTaskFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  navigateToTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.navigateToTask),
      concatLatestFrom(() => this.store.select(selectCurrentTaskId)),
      switchMap(([{ id, create }, taskId]) => [
        TaskActions.setCurrentTask({ id: id || taskId }),
        RouterActions.navigateForward({ url: `/task/${id || taskId}`, options: create && { queryParams: { create } } }),
      ]),
    ),
  );

  navigateToTaskList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.navigateToTaskList),
      concatLatestFrom(() => this.store.select(selectCurrentSiteId)),
      switchMap(([{ siteId }, id]) => [SiteActions.setCurrentSite({ id: siteId || id }), RouterActions.navigateForward({ url: '/tasks' })]),
    ),
  );

  createTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.createTask),
      concatLatestFrom((action) => this.store.select(selectCurrentTaskId)),
      exhaustMap(([{ input }, tmpTaskId]) =>
        this.task.create(input).pipe(
          map(({ task }) => TaskActions.createTaskSuccess({ task, tmpTaskId })),
          catchError((error) => of(TaskActions.createTaskFailure({ error }))),
        ),
      ),
    ),
  );

  createTaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.createTaskSuccess),
      // Force pop the task detail page, then navigate to new task
      // Using RouterActions doesn't work because I think the navigateForward is called before pop completes and gets cancelled
      switchMap(({ task }) =>
        defer(() => this.nav.navigateBack('/tasks')).pipe(
          switchMap(() => [
            TaskActions.navigateToTask({ id: task.id }),
            MediaActions.createUnsavedFiles({
              workflowId: task.workflow.id,
              taskId: task.id,
              stepId: task.workflow.stepId,
            }),
          ]),
        ),
      ),
      // map(({ task }) => MediaActions.uploadUnsaved({ workflowId: task?.workflow?.id })),
      // switchMap(({ task: { id } }) => [RouterActions.navigateBack({ url: '/tasks' }), TaskActions.navigateToTask({ id })]),
    ),
  );

  createTaskFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.createTaskFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  constructor(private actions$: Actions, private nav: NavController, private store: Store, private task: TaskService) {}
}
