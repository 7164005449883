import { createReducer, on } from '@ngrx/store';
import { UserAttributes } from 'src/app/models/client/user.model';
import { User } from 'src/app/models/server/schema';

import { AppActions, UserActions } from '../actions';

export const featureKey = 'user';

export interface State extends Partial<UserAttributes>, Partial<User> {
  loading: boolean;
  password?: string; // only used to prevent entering password twice during confirmation
}

export const initialState: State = { loading: false };

export const reducer = createReducer(
  initialState,
  on(AppActions.applicationBegin, (state): State => ({ ...state, loading: false })),

  on(UserActions.login, (state, { email }): State => ({ ...state, loading: true, email })),
  on(UserActions.loginSuccess, (state, { user }) => ({ ...state, ...user, loading: false, password: undefined })),
  on(UserActions.loginFailure, (state): State => ({ ...state, loading: false })),

  on(UserActions.registerUser, (state): State => ({ ...state, loading: true })),
  on(UserActions.registerUserSuccess, (state, { user, password }) => ({ ...user, password, loading: false })),
  on(UserActions.registerUserFailure, (state): State => ({ ...state, loading: false })),

  on(UserActions.confirmRegistration, (state): State => ({ ...state, loading: true })),
  on(UserActions.confirmRegistrationSuccess, (state): State => ({ ...state, password: undefined, loading: false })),
  on(UserActions.confirmRegistrationFailure, (state): State => ({ ...state, loading: false })),

  on(UserActions.resendConfirmation, (state, { email }) => ({ ...state, email, loading: true })),
  on(UserActions.resendConfirmationSuccess, (state): State => ({ ...state, loading: false })),
  on(UserActions.resendConfirmationFailure, (state): State => ({ ...state, loading: false })),

  on(UserActions.forgotPassword, (state, { email }) => ({ email, loading: true })),
  on(UserActions.forgotPasswordSuccess, (state): State => ({ ...state, loading: false })),
  on(UserActions.forgotPasswordFailure, (state): State => ({ ...state, loading: false })),

  on(UserActions.verifyPasswordReset, (state, { email, password }) => ({ email, password, loading: true })),
  on(UserActions.verifyPasswordResetSuccess, (state): State => ({ ...state, loading: false })),
  on(UserActions.verifyPasswordResetFailure, (state): State => ({ ...state, loading: false })),

  on(UserActions.logout, () => initialState),
);

export const getId = (state: State) => state.id;
export const getName = (state: State) => `${state.firstName} ${state.lastName}`;
export const getEmail = (state: State) => state.email;
export const getPassword = (state: State) => state.password;
export const getLoggedIn = (state: State) => !!state.id;
export const getLoading = (state: State) => state.loading;
