import { Environment } from 'src/app/models/client/environment.model';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  gqlHost: 'https://exg6pjwehrhgnhksukm7lsslry.appsync-api.us-west-2.amazonaws.com/graphql',
  glidecloudTenantId: '00f82a4b-e8ae-44e3-9c5e-ba8d4220cdcb',
  anonymousUser: 'anonymous+maintain@busycloud.us',
  anonymousPassword: 'FFu_+QFFJ9P*CC^p',
  googleMapsKey: process.env.GOOGLE_MAPS_KEY,
  wssHost: 'wss://api.dev.glidecloud.io',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

import 'zone.js/plugins/zone-error';
