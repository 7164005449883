import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

import { ErrorActions } from '../actions';

@Injectable()
export class ErrorEffects {
  handleError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActions.handleError),
        map(({ error, source }) => this.error.handleError(error, source)),
      ),
    { dispatch: false },
  );

  showWarning$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActions.showWarning),
        map(({ error, source }) => this.error.showWarning(error, source)),
      ),
    { dispatch: false },
  );

  showAlertMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActions.showAlertMessage),
        map(({ message }) => this.error.showAlertMessage(message)),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private error: ErrorHandlerService) {}
}
