import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sites',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterPageModule),
  },
  {
    path: 'verification',
    loadChildren: () => import('./pages/verification/verification.module').then((m) => m.VerificationPageModule),
  },
  {
    path: 'verify-password-reset',
    loadChildren: () => import('./pages/verify-password-reset/verify-password-reset.module').then((m) => m.VerifyPasswordResetPageModule),
  },
  {
    path: 'sites',
    loadChildren: () => import('./pages/site-list/site-list.module').then((m) => m.SiteListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'site/:id',
    loadChildren: () => import('./pages/site/site.module').then((m) => m.SitePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'site-add',
    loadChildren: () => import('./pages/site-add/site-add.module').then((m) => m.SiteAddPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/task-list/task-list.module').then((m) => m.TaskListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'task/:id',
    loadChildren: () => import('./pages/task/task.module').then((m) => m.TaskPageModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
