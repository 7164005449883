import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { FileCreateGQL, FileCreateInput, FileCreateMutation, FilesByWorkflowGQL } from '../models/server/schema';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private fileCreateGQL: FileCreateGQL,
    private filesByWorkflowGQL: FilesByWorkflowGQL,
    private http: HttpClient,
  ) {}

  create(input: FileCreateInput) {
    return this.fileCreateGQL.mutate({ input }).pipe(map(({ data: fileCreate }) => fileCreate));
  }

  upload(file: FileCreateMutation, blob: Blob) {
    const formData = new FormData();
    file.fileCreate.metaFields.forEach((meta) => formData.append(meta.name, meta.value));
    formData.append('file', blob, file.fileCreate.name);
    return this.http.post<void>(file.fileCreate.url, formData);
  }

  filesByWorkflow(workflowId: string) {
    return this.filesByWorkflowGQL.fetch({ workflowId }).pipe(map(({ data: { filesByWorkflow } }) => filesByWorkflow));
  }

  base64ToBlob(base64: string, type: string): Blob {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
}
