import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ServiceLineService } from 'src/app/services/service-line.service';

import { ErrorActions, ServiceLineActions } from '../actions';

@Injectable()
export class ServiceLineEffects {
  fetchServiceLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceLineActions.fetchServiceLines),
      exhaustMap(() =>
        this.serviceLine.list().pipe(
          map(({ items }) => ServiceLineActions.fetchServiceLinesSuccess({ serviceLines: items })),
          catchError((error) => of(ServiceLineActions.fetchServiceLinesFailure({ error }))),
        ),
      ),
    ),
  );

  fetchServiceLinesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceLineActions.fetchServiceLinesFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  createSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceLineActions.createServiceLine),
      exhaustMap(({ input }) =>
        this.serviceLine.create(input).pipe(
          map(({ serviceLine }) => ServiceLineActions.createServiceLineSuccess({ serviceLine })),
          catchError((error) => of(ServiceLineActions.createServiceLineFailure({ error }))),
        ),
      ),
    ),
  );

  createSiteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceLineActions.createServiceLineFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  constructor(private actions$: Actions, private serviceLine: ServiceLineService) {}
}
