/// <reference types="@types/dom-mediacapture-record" />
import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Microphone } from '@mozartec/capacitor-microphone';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { ErrorActions, MediaActions } from '../state/actions';
import { selectCurrentTaskId } from '../state/reducers';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  mediaRecorder: MediaRecorder;
  recordedChunks: Blob[];

  constructor(private ngZone: NgZone, private platform: Platform, private store: Store) {}

  async startAudio() {
    if (this.platform.is('capacitor')) {
      await this.capacitorStartAudio();
    } else {
      await this.browserStartAudio();
    }
  }

  async stopAudio() {
    if (this.platform.is('capacitor')) {
      await this.capacitorStopAudio();
    } else {
      await this.browserStopAudio();
    }
  }

  async capacitorStartAudio() {
    let { microphone } = await Microphone.checkPermissions();

    if (microphone === 'denied') {
      throw new Error('Microphone permission denied');
    } else if (microphone === 'prompt' || microphone === 'prompt-with-rationale') {
      const permission = await Microphone.requestPermissions();
      microphone = permission.microphone;
    }

    if (microphone === 'granted') {
      await Microphone.startRecording();
    }
  }

  async browserStartAudio() {
    const stream: MediaStream = await navigator.mediaDevices?.getUserMedia({ audio: true });

    if (!stream) {
      return; // audio permission denied
    }

    this.mediaRecorder = new MediaRecorder(stream);
    this.mediaRecorder.onerror = (e: any) => this.store.dispatch(ErrorActions.handleError(e.error));
    this.mediaRecorder.ondataavailable = ({ data }: BlobEvent) => (this.recordedChunks = [...this.recordedChunks, data]);

    this.mediaRecorder.onstop = (e: Event) => {
      const reader = new FileReader();
      const type = this.recordedChunks?.[0].type;
      reader.readAsDataURL(new Blob(this.recordedChunks, { type }));

      reader.onload = () =>
        this.ngZone.run(async () => {
          const taskId = await this.store.select(selectCurrentTaskId).pipe(first()).toPromise();
          this.store.dispatch(
            MediaActions.stopAudioRecordSuccess({
              item: {
                id: `${uuidv4()}.${type.split(';')[0].split('/')[1]}`,
                base64String: reader.result as string,
                mimeType: type.split(';')[0],
                taskId,
                image: false,
                audio: true,
                uploadPending: true,
              },
            }),
          );
        });
    };

    this.recordedChunks = [];
    this.mediaRecorder.start();
  }

  async capacitorStopAudio() {
    const audioRecording = await Microphone.stopRecording();
    console.log(audioRecording);
    this.ngZone.run(async () => {
      const taskId = await this.store.select(selectCurrentTaskId).pipe(first()).toPromise();
      this.store.dispatch(
        MediaActions.stopAudioRecordSuccess({
          item: {
            id: `${uuidv4()}${audioRecording.format}`,
            base64String: audioRecording.dataUrl,
            mimeType: audioRecording.mimeType,
            taskId,
            image: false,
            audio: true,
            uploadPending: true,
          },
        }),
      );
    });
  }

  browserStopAudio() {
    this.mediaRecorder?.stop();
    this.mediaRecorder?.removeAllListeners();
    this.mediaRecorder = undefined;
    this.recordedChunks = [];
  }
}
