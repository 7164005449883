import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Task } from 'src/app/models/client/task.model';

import { AppActions, UserActions } from '../actions';
import * as TaskActions from '../actions/task.actions';

export const featureKey = 'task';

export interface State extends EntityState<Task> {
  // additional entities state properties
  currentEntityId: string | null;
  listMode: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: (task: Task): string => task.id,
  sortComparer: (a: Task, b: Task): number => a.name?.localeCompare(b.name),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  currentEntityId: null,
  listMode: true,
  loading: false,
});

const entityReducer = createReducer(
  initialState,
  on(AppActions.applicationBegin, (state): State => ({ ...state, loading: false })),

  on(TaskActions.fetchTasks, (state): State => ({ ...state, loading: true })),
  on(TaskActions.fetchTasksSuccess, (state, { tasks }): State => adapter.setAll(tasks, { ...state, loading: false })),
  on(TaskActions.fetchTasksFailure, (state): State => ({ ...state, loading: false })),

  on(TaskActions.fetchTask, (state): State => ({ ...state, loading: true })),
  on(TaskActions.fetchTaskSuccess, (state, { task }): State => adapter.setOne(task, { ...state, loading: false })),
  on(TaskActions.fetchTaskFailure, (state): State => ({ ...state, loading: false })),

  on(TaskActions.createTask, (state): State => ({ ...state, loading: true })),
  on(TaskActions.createTaskSuccess, (state, { task }): State => adapter.upsertOne(task, { ...state, loading: false })),
  on(TaskActions.createTaskFailure, (state): State => ({ ...state, loading: false })),

  on(TaskActions.addTask, (state, { task }): State => adapter.addOne(task, state)),
  on(TaskActions.setTask, (state, { task }): State => adapter.setOne(task, state)),
  on(TaskActions.upsertTask, (state, { task }): State => adapter.upsertOne(task, state)),

  on(TaskActions.addTasks, (state, { tasks }): State => adapter.addMany(tasks, state)),
  on(TaskActions.upsertTasks, (state, { tasks }): State => adapter.upsertMany(tasks, state)),

  on(TaskActions.updateTask, (state, { update }): State => adapter.updateOne(update, { ...state, loading: true })),
  on(
    TaskActions.updateTaskSuccess,
    (state, { task }): State => adapter.updateOne({ id: task.id, changes: task }, { ...state, loading: false }),
  ),
  on(TaskActions.updateTaskFailure, (state): State => ({ ...state, loading: false })),

  on(TaskActions.updateTasks, (state, { updates }): State => adapter.updateMany(updates, state)),

  on(TaskActions.mapTask, (state, { entityMap }): State => adapter.mapOne(entityMap, state)),
  on(TaskActions.mapTasks, (state, { entityMap }): State => adapter.map(entityMap, state)),

  on(TaskActions.deleteTask, (state, { id }): State => adapter.removeOne(id, state)),

  on(TaskActions.deleteTasks, (state, { ids }): State => adapter.removeMany(ids, state)),
  on(TaskActions.deleteTasksByPredicate, (state, { predicate }): State => adapter.removeMany(predicate, state)),

  on(TaskActions.loadTasks, (state, { tasks }): State => adapter.setAll(tasks, state)),

  on(TaskActions.clearTasks, (state): State => adapter.removeAll({ ...state, currentTaskId: null })),
  on(UserActions.logout, (state): State => adapter.removeAll({ ...state, ...initialState })),

  on(TaskActions.setCurrentTask, (state, { id }): State => ({ ...state, currentEntityId: id })),
  on(TaskActions.clearCurrentTask, (state): State => ({ ...state, currentEntityId: null })),

  on(TaskActions.toggleListMode, (state): State => ({ ...state, listMode: !state.listMode })),
);

export function reducer(state: State | undefined, action: Action) {
  return entityReducer(state, action);
}

// get the selectors
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getByAllSubjectId = (tasks: Task[], id: string): Task[] =>
  tasks.filter((task) => task.subjects?.some((subject) => subject.id === id));

export const getCurrentEntityId = (state: State): string => state.currentEntityId;
export const getIfListMode = (state: State): boolean => state.listMode;
export const getListIcon = (state: State): 'map-outline' | 'list-outline' => (state.listMode ? 'map-outline' : 'list-outline');

export const getById = (tasks: Dictionary<Task>, id: string): Task => tasks[id];
export const getName = (task: Task): string => task?.name;
export const getIfDisplayDrawer = (task: Task, listMode: boolean): boolean => !!task && !listMode;

export const getLoading = (state: State): boolean => state.loading;
