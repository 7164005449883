import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';

export const createApollo = (httpLink: HttpLink, router: Router): ApolloClientOptions<any> => {
  const options = setContext(async () => {
    const abortController = new AbortController();
    let token = '';

    try {
      token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    } catch (e) {
      console.error(e);
      abortController.abort();
      await router.navigateByUrl('/login');
    }
    return {
      uri: environment.gqlHost,
      headers: {
        'x-glidecloud-tenant-id': environment.glidecloudTenantId,
        Authorization: token,
      },
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    };
  });

  return {
    link: ApolloLink.from([options, httpLink.create({})]),
    cache: new InMemoryCache(),
  };
};

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Router],
    },
  ],
})
export class GraphQLModule {}
