import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { SiteService } from 'src/app/services/site.service';

import { ErrorActions, RouterActions, SiteActions } from '../actions';
import { selectCurrentSiteId } from '../reducers';

@Injectable()
export class SiteEffects {
  fetchSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.fetchSites),
      exhaustMap(() =>
        this.site.list().pipe(
          map(({ items }) => SiteActions.fetchSitesSuccess({ sites: items })),
          catchError((error) => of(SiteActions.fetchSitesFailure({ error }))),
        ),
      ),
    ),
  );

  fetchSitesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.fetchSitesFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  fetchSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.fetchSite),
      exhaustMap(({ id }) =>
        this.site.retreive(id).pipe(
          map((site) => SiteActions.fetchSiteSuccess({ site })),
          catchError((error) => of(SiteActions.fetchSiteFailure({ error }))),
        ),
      ),
    ),
  );

  fetchSiteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.fetchSiteFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  navigateToSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.navigateToSite),
      concatLatestFrom(() => this.store.select(selectCurrentSiteId)),
      switchMap(([{ id }, siteId]) => [
        SiteActions.setCurrentSite({ id: id || siteId }),
        RouterActions.navigateForward({ url: `/site/${id || siteId}` }),
      ]),
    ),
  );

  // navigateToSiteList$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(SiteActions.navigateToSiteList),
  //     switchMap(() => [SiteActions.clearCurrentSite(), RouterActions.navigateBack({ url: '/sites' })]),
  //   ),
  // );

  createSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.createSite),
      exhaustMap(({ input }) =>
        this.site.create(input).pipe(
          map(({ site }) => SiteActions.createSiteSuccess({ site })),
          catchError((error) => of(SiteActions.createSiteFailure({ error }))),
        ),
      ),
    ),
  );

  createSiteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.createSiteFailure),
      map(({ error }) => ErrorActions.handleError({ error })),
    ),
  );

  constructor(private actions$: Actions, private site: SiteService, private store: Store) {}
}
