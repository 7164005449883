import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { CognitoUserExt, UserAttributes } from '../models/client/user.model';
import { ConfirmUserGQL, CurrentUserGQL, RegisterUserGQL, ResetPasswordGQL, User, UserRole } from '../models/server/schema';
import { CountryPhone } from '../validators/country-phone';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  countryPhone = new CountryPhone('US', 'United States');

  constructor(
    private confirmUserGQL: ConfirmUserGQL,
    private currentUser: CurrentUserGQL,
    private registerUserGQL: RegisterUserGQL,
    private resetPasswordGQL: ResetPasswordGQL,
  ) {}

  queryCurrentUser(): Observable<User> {
    return this.currentUser.fetch().pipe(map(({ data: { currentUser } }) => currentUser as any as User));
  }

  login(email: string, password: string): Observable<UserAttributes> {
    return defer(() => Auth.signIn(email, password)).pipe(map((user: CognitoUserExt) => user.attributes));
  }

  registerUser(firstName: string, lastName: string, role: UserRole, phone: string, email: string, password: string) {
    return this.registerUserGQL
      .mutate({
        userRegisterInput: {
          firstName,
          lastName,
          phone: this.countryPhone.countryCode + phone.replace(this.countryPhone.countryCode, '').split('').filter(Number).join(''),
          email,
          password,
          tenantId: environment.glidecloudTenantId,
          roles: [role],
        },
      })
      .pipe(map(({ data: { userManagement } }) => userManagement));
  }

  confirmRegistration(code: string, email: string, password: string) {
    return this.confirmUserGQL
      .mutate({ userConfirmationInput: { code, password, username: email } })
      .pipe(map(({ data: { userManagement } }) => userManagement));
  }

  resendConfirmation(username: string) {
    return this.confirmUserGQL
      .mutate({ userConfirmationInput: { username, resend: true } })
      .pipe(map(({ data: { userManagement } }) => userManagement));
  }

  forgotPassword(username: string) {
    return this.resetPasswordGQL
      .mutate({ userPasswordResetInput: { username } })
      .pipe(map(({ data: { userManagement } }) => userManagement));
  }

  verifyPasswordReset(code: string, email: string, password: string) {
    return this.resetPasswordGQL
      .mutate({ userPasswordResetInput: { username: email, code, password } })
      .pipe(map(({ data: { userManagement } }) => userManagement));
  }
}
