<ion-app>
  <ion-menu contentId="main-content">
    <ion-content>
      <ion-list lines="inset">
        <ion-list-header lines="inset">
          <ion-label>
            <h1>
              MAINTAIN
            </h1>
            <ion-note>
              {{ email$ | async }}
            </ion-note>
          </ion-label>
        </ion-list-header>

        <ion-menu-toggle auto-hide="false">
          <ion-item (click)="logout()" button>
            <ion-icon slot="start" ios="log-out-outline" md="log-out-sharp"></ion-icon>
            <ion-label>Log Out</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>

</ion-app>