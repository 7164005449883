import { createReducer, on } from '@ngrx/store';

import { GeolocationActions, MapActions } from '../actions';

export const featureKey = 'map';

export interface State {
  enabled: boolean;
  sitesZoom: number;
  tasksZoom: number;
  currentPosition: google.maps.LatLngLiteral;
  currentSitesPosition: google.maps.LatLngLiteral;
  currentTasksPosition: google.maps.LatLngLiteral;
}

const txStateCapitol = { lat: 30.274665, lng: -97.74035 };
export const initialState: State = {
  enabled: false,
  sitesZoom: 10,
  tasksZoom: 10,
  currentPosition: txStateCapitol,
  currentSitesPosition: txStateCapitol,
  currentTasksPosition: txStateCapitol,
};

export const reducer = createReducer(
  initialState,
  on(MapActions.loadMapSuccess, (state): State => ({ ...state, enabled: true })),
  on(MapActions.loadMapFailure, (state): State => ({ ...state, enabled: false })),

  on(
    GeolocationActions.watchPositionSuccess,
    (state, { coords: { latitude, longitude } }): State => ({ ...state, currentPosition: { lat: latitude, lng: longitude } }),
  ),

  on(MapActions.recenterSitesPosition, (state): State => ({ ...state, currentSitesPosition: { ...state.currentPosition } })),
  on(MapActions.recenterTasksPosition, (state): State => ({ ...state, currentTasksPosition: { ...state.currentPosition } })),

  on(MapActions.setCurrentPosition, (state, { currentPosition }): State => ({ ...state, currentPosition })),
  on(MapActions.setSitesZoom, (state, { sitesZoom }): State => ({ ...state, sitesZoom })),
  on(MapActions.setTasksZoom, (state, { tasksZoom }): State => ({ ...state, tasksZoom })),
);

export const getEnabled = ({ enabled }: State): boolean => enabled;
export const getSitesZoom = ({ sitesZoom }: State): number => sitesZoom;
export const getTasksZoom = ({ tasksZoom }: State): number => tasksZoom;
export const getCurrentPosition = ({ currentPosition }: State): google.maps.LatLngLiteral => currentPosition;
export const getCurrentSitesPosition = ({ currentSitesPosition }: State): google.maps.LatLngLiteral => currentSitesPosition;
export const getCurrentTasksPosition = ({ currentTasksPosition }: State): google.maps.LatLngLiteral => currentTasksPosition;
